
import { Component, Prop } from 'vue-property-decorator';

import ButtonDefault from '@/components/shared/ButtonDefault.vue';

import NotificationFlow from '@/mixins/NotificationFlow';

import {
  NotificationApi,
  NotificationList,
} from '@/models/api';
import { RouteName } from '@/models';

@Component({
  name: 'NotificationsPageBox',
  components: {
    ButtonDefault,
  },
})
export default class NotificationsPageBox extends NotificationFlow {
  @Prop({ default: true }) dark: boolean;

  public notificationList: NotificationList[] = [];
  public page = 1;
  public loading = false;
  private showMoreText = true;

  public get showMore(): boolean {
    return this.showMoreText;
  }

  public set showMore(show: boolean) {
    this.showMoreText = show;
  }

  public get list(): NotificationList[] {
    return this.notificationList;
  }

  mounted() {
    this.notificationList = this.notificationApi.data;
    if (!this.notificationApi.data.length) this.handleNotifications();
  }

  public viewMore(): void {
    this.page++;
    this.handleNotifications();
  }

  public markNotificationAsRead({ id, url }: NotificationList, index: number): void {
    const dictionary: {
      [index: string]: RouteName;
    } = {
      PUBLISHED_ASSIGNMENT: RouteName.PUBLISHED_ASSIGNMENT,
    };

    if (url) {
      const { rota, parametros } = url;
      this.markAsRead(id).then(() => {
        this.$router.push({
          name: dictionary[rota],
          params: { entregaId: parametros.id },
        });

        this.toggleVisibility();
      });

      return;
    }

    this.markAsRead(id).then(
      () => this.notificationList[index].visualizada = true,
    );
  }

  public handleNotifications(): void {
    this.loading = true;
    this.getNotifications(this.page)
      .then(({ data, per_page }: NotificationApi) => {
        if (data) this.notificationList = this.notificationList.concat(data);
        this.showMore = Boolean(data.length !== 0 || data.length === per_page);
      })
      .finally(() => (this.loading = false));
  }
}
