
import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import Casa from '@/assets/icons/Casa.svg';
import Clipboard from '@/assets/icons/Clipboard.svg';

import Notifications from '@/components/Notifications/Notifications.vue';

import { RouteName } from '@/models';

@Component({
  name: 'FloatingHeader',
  computed: {
    ...mapGetters('headerDisplay', {
      showFloatingHeaderItens: 'showFloatingHeaderItens',
    }),
  },
  methods: {
    ...mapActions('headerDisplay', {
      checkWidthSize: 'checkWidthSize',
    }),
  },
  components: {
    Casa,
    Clipboard,
    Notifications,
  },
})
export default class FloatingHeader extends Vue {
  public checkWidthSize!: () => void;
  public myAssignments = RouteName.PENDING_ASSIGNMENTS;
  public startPage = RouteName.INICIO;

  mounted() {
    window.addEventListener('resize', this.checkWidthSize);
    this.checkWidthSize();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidthSize);
  }
}
