
import { Component, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';

import Sino from '@/assets/icons/Sino.svg';

import NotificationsPageBox from '@/components/Notifications/NotificationsPageBox.vue';

import NotificationFlow from '@/mixins/NotificationFlow';

import { NotificationApi } from '@/models/api';

@Component({
  name: 'Notifications',
  components: {
    NotificationsPageBox,
    Sino,
  },
})
export default class Notifications extends NotificationFlow {
  @Prop({ default: false }) public hasHover!: boolean;
  @Prop({ default: false }) public dark!: boolean;
  public number: number | null = null;

  mounted() {
    this.handleNotifications();
  }

  public handleNotifications() {
    this.getNotifications()
      .then((n: NotificationApi) => (this.number = n?.notificacoesPendentes))
      .catch((err: AxiosError) => console.error(err));
  }
}
