import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapGetters } from 'vuex';

import { NotificacaoVisualizada, NotificationApi } from '@/models/api';

@Component({
  name: 'NotificationFlow',
  computed: {
    ...mapGetters('notification', {
      isBoxMode: 'isBoxMode',
      isPageMode: 'isPageMode',
      isOpened: 'isOpened',
      notificationApi: 'notificationApi',
    }),
  },
  methods: {
    ...mapActions('notification', {
      checkMode: 'checkMode',
      toggleVisibility: 'toggleVisibility',
      getNotifications: 'getNotifications',
      markAsRead: 'markAsRead',
    }),
  },
})
export default class NotificationFlow extends Vue {
  public checkMode!: () => void;
  public toggleVisibility!: () => boolean;
  public getNotifications!: (page?: number) => Promise<NotificationApi>;
  public markAsRead!: (id: number) => Promise<NotificacaoVisualizada>;
  public isBoxMode!: boolean;
  public isOpened!: boolean;
  public notificationApi!: NotificationApi;

  mounted() {
    this.subscribeToEvents();
    this.checkMode();
  }

  subscribeToEvents() {
    document.addEventListener('keyup', this.handleEscKeyup);
    window.addEventListener('resize', this.checkMode);
  }

  unsubscribeToEvents() {
    document.removeEventListener('keyup', this.handleEscKeyup);
    window.removeEventListener('resize', this.checkMode);
  }

  handleEscKeyup(event: KeyboardEvent) {
    const isEscapeKey = event.keyCode === 27;
    if (isEscapeKey && this.isOpened) this.toggleVisibility();
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }
}
