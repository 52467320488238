
import { Component, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { mapGetters } from 'vuex';

import LogoFiaNetworking from '@/assets/img/LogoFiaNetworking.svg';

import NotificationsPageBox from '@/components/Notifications/NotificationsPageBox.vue';

import NotificationFlow from '@/mixins/NotificationFlow';

import { RouteName, Course } from '@/models';

import FloatingHeader from './FloatingHeader.vue';
import ActionsHeader from './ActionsHeader.vue';

const {
  INICIO,
  ASSIGNMENT_OBJECT_MANAGE,
  COURSES,
  PENDING_ASSIGNMENTS,
} = RouteName;

@Component({
  name: 'Header',
  components: {
    LogoFiaNetworking,
    ActionsHeader,
    FloatingHeader,
    NotificationsPageBox,
  },
  computed: {
    ...mapGetters('user', {
      getUserCourses: 'getUserCourses',
      getUserCourseId: 'getUserCourseId',
      isStudent: 'isStudent',
    }),
  },
})
export default class Header extends NotificationFlow {
  @Prop({ default: true }) dark: boolean;
  @Prop({ default: true }) showNotifications: boolean;
  @Prop({ default: true }) showUserProfile: boolean;
  @Prop({ default: true }) showFloatingHeader: boolean;

  public isStudent: boolean;

  private getUserCourses: Course[];
  private getUserCourseId: number;

  public inicio: Location;
  public pending = { name: PENDING_ASSIGNMENTS };

  created() {
    this.setRoute();
  }

  private setRoute(): void {
    const hasMoreOneCourse = this.getUserCourses.length > 1;

    const cursoId = this.getUserCourseId.toString();

    const routes = [
      { name: ASSIGNMENT_OBJECT_MANAGE },
      { name: INICIO, params: { cursoId } },
      { name: COURSES },
    ];

    const studentVerification = hasMoreOneCourse ? 2 : 1;
    const routeKey = this.isStudent ? studentVerification : 0;

    this.inicio = routes[routeKey];
  }
}
