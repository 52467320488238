
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import Trocar from '@/assets/icons/Trocar.svg';

import Avatar from '@/components/shared/Avatar.vue';
import ChevronUp from '@/assets/icons/ChevronUp.svg';
import FloatingMenu from '@/components/FloatingMenu/FloatingMenu.vue';
import Notifications from '@/components/Notifications/Notifications.vue';

import { RouteName } from '@/models/route-name';
import {
  SidebarTypes,
  FloatingMenuContent,
  FloatingMenuModel,
  FloatingMenuPosition,
} from '@/models';

import { SidebarService } from '@/services';

const {
  PROFILE,
  LOADING,
  LOGIN,
} = RouteName;
const { LINK_TYPE } = FloatingMenuContent;
const { LEFT } = FloatingMenuPosition;

@Component({
  name: 'ActionsHeader',
  computed: {
    ...mapGetters('headerDisplay', {
      showActionHeaderItens: 'showActionHeaderItens',
    }),
    ...mapGetters('user', {
      getUserName: 'getUserName',
      getUserNickname: 'getUserNickname',
      getUserPhoto: 'getUserPhoto',
      isStudent: 'isStudent',
    }),
  },
  methods: {
    ...mapActions('headerDisplay', {
      checkWidthSize: 'checkWidthSize',
    }),
  },
  components: {
    Avatar,
    ChevronUp,
    FloatingMenu,
    Notifications,
    Trocar,
  },
})
export default class ActionsHeader extends Vue {
  @Prop({ default: true }) dark!: boolean;
  @Prop({ default: true }) showTrocar: boolean;
  @Prop({ default: true }) showNotifications: boolean;
  @Prop({ default: true }) showUserProfile: boolean;
  public checkWidthSize!: () => void;
  public getUserName!: string;
  public getUserNickname!: string;
  public getUserPhoto!: string;
  public isStudent: boolean;

  public rotate = false;
  public routeName = RouteName.PENDING_ASSIGNMENTS;

  public headerMenu: FloatingMenuModel = {
    darkMode: this.dark,
    bordered: true,
    showMenu: false,
    position: LEFT,
    data: [{
      type: LINK_TYPE,
      id: 0,
      text: 'EDITAR PERFIL',
      name: PROFILE,
    }, {
      type: LINK_TYPE,
      id: 1,
      text: 'SAIR',
      name: LOADING,
      params: {
        routename: LOGIN,
      },
    }],
  };

  mounted() {
    this.checkWidthSize();
    this.subscribeToEvents();
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }

  private subscribeToEvents() {
    window.addEventListener('resize', this.checkWidthSize);
  }

  private unsubscribeToEvents() {
    window.removeEventListener('resize', this.checkWidthSize);
  }

  public sidebarCourse() {
    SidebarService.abrirSidebar({
      component: SidebarTypes.COURSESIDEBAR,
      fechavel: true,
    });
  }

  public rotateChevron(rotate: boolean) {
    this.rotate = rotate;
  }
}
